<div class="video" *ngIf="data.id; else unauthorizedContentTemplate">
  <h2 class="back-title">
    <div (click)="additionalService.backClicked()">
      <span class="icon icon-Arrow-Left"></span>
    </div>
  </h2>

  <div *ngIf="data.videoUrl" class="video__player">
    <iframe
      [src]="data.videoUrl | safeUrl"
      width="100%"
      height="100%"
      title=""
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowfullscreen="true"></iframe>
  </div>

  <div class="video__data">
    <div class="video__data-info">
      <div class="video__data-info__date">{{ data.publishDate | customDate }}</div>
      <h1 class="video__data-info__title">{{ data.name }}</h1>
    </div>
    <div class="video__data-btns">
      <div class="video__data-btns__share-wrapper" appClickedOutside (clickOutside)="closeSharePopover()">
        <div class="video__data-btns__share" (click)="openSharePopover()">
          <span class="icon-Soc-Share"></span> Share
        </div>
        <app-share-popover
          *ngIf="isSharePopoverOpen"
          [metaTags]="unauthorizedContent"
          (closeEvent)="closeSharePopover()"></app-share-popover>
      </div>
      <div class="video__data-btns__author" *ngIf="data.author">
        <img [src]="data.author.avatarUrl" [alt]="data.author.name" lazyLoad />
        <p>{{ data.author.name }}</p>
      </div>
    </div>
  </div>

  <h1 class="video__title">{{ data.name }}</h1>
  <div class="video__description">{{ data.description }}</div>

  <app-reactions-list
    class="video__reactions"
    [reactions]="reactions"
    [position]="popoverListPosition"
    (selectReactionEvent)="onSelectReaction($event)"></app-reactions-list>
  <app-comments-list
    class="video__comments"
    [id]="data.id"
    [comments]="comments"
    [service]="videosService"
    (changeSorting)="updateCommentsOrder($event)"></app-comments-list>
</div>

<ng-template #unauthorizedContentTemplate>
  <app-unauthorized-content
    *ngIf="unauthorizedContent && !loading"
    [content]="unauthorizedContent"
    [isAccessRestricted]="isAccessRestricted"></app-unauthorized-content>
</ng-template>
