<div class="carousel">
  <div class="carousel-wrapper">
    <div class="carousel-container" #modalContainer>
      <div class="carousel-actions">
        <app-filter-authors
          [params]="storiesService.params"
          [selectedAuthorsIds]="storiesService.selectedAuthors"
          (selectAuthors)="onAuthorsSelectionChange($event)"></app-filter-authors>
        <app-filter-sorting
          [selectedSorting]="storiesService.params.sort"
          (changeSorting)="onChangeSortingOrder($event)"></app-filter-sorting>
      </div>
      <ng-container *ngIf="currentItem; else emptyItem">
        <div class="carousel-content">
          <img *ngIf="currentItem.imageUrl" [src]="currentItem.imageUrl" alt="Stories" lazyLoad />
          <div *ngIf="mediaVideoUrl" class="player">
            <iframe
              [src]="mediaVideoUrl"
              style="border: none"
              width="100%"
              height="100%"
              title=""
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"></iframe>
          </div>

          <div *ngIf="!contentService.isContentVisible(currentItem.tier)" (click)="goToPackages()" class="unlock">
            <span>Unlock</span>
            <span class="icon icon-Arrow-Down-Left"></span>
          </div>

          <div class="author">
            <img [src]="currentItem.author.avatarUrl" [alt]="currentItem.author.name" lazyLoad />
            <p>{{ currentItem.author.name }}</p>
          </div>

          <div class="carousel-controls-prev-wrapper" (click)="onPrevClick()" *ngIf="isPrevBtnVisible">
            <div class="carousel-controls-prev">
              <span class="icon-Arrow-Left"></span>
            </div>
          </div>
          <div class="carousel-controls-next-wrapper" (click)="onNextClick()" *ngIf="isNextBtnVisible">
            <div class="carousel-controls-next">
              <span class="icon-Arrow-Right"></span>
            </div>
          </div>
        </div>
        <div class="carousel-deatils" *ngIf="currentItem">
          <div class="carousel-deatils__reactions">
            <span *ngIf="reactions.length === 0" class="icon-Add-Reaction"></span>
            <app-reactions-list
              *ngIf="reactions.length > 0"
              [reactions]="reactions"
              [position]="popoverListPosition"
              (selectReactionEvent)="onSelectReaction($event)"></app-reactions-list>
          </div>
          <div class="carousel-deatils__comments" (click)="openCommentsPopup()">
            <span class="icon icon-Comments"></span>
            <span *ngIf="comments.length > 0" class="count">{{ comments.length }}</span>
          </div>
        </div>

        <div class="carousel-comments" *ngIf="isCommentsVisible" [ngStyle]="commentsPosition">
          <div class="carousel-comments__header">
            Comments <span *ngIf="comments.length > 0" class="count">{{ comments.length }}</span>
            <span class="close icon-Close-Bold" (click)="openCommentsPopup()"></span>
          </div>
          <div class="carousel-comments__body">
            <app-filter-sorting
              [selectedSorting]="commentsOrder"
              (changeSorting)="onChangeCommentsSortingOrder($event)"></app-filter-sorting>

            <app-stories-comments-list
              class="carousel-comments__list"
              [id]="currentItem.id"
              [comments]="comments"
              [sortingOrder]="commentsOrder"></app-stories-comments-list>
          </div>
        </div>
      </ng-container>

      <ng-template #emptyItem>
        <div class="carousel-content" *ngIf="!currentItem">
          <app-empty class="carousel-content__empty"></app-empty>
        </div>
      </ng-template>
    </div>

    <div
      class="carousel-preview"
      *ngIf="(storiesService.stories$ | async) && (storiesService.stories$ | async)!.length > 0">
      <drag-scroll
        [drag-scroll-y-disabled]="true"
        [scroll-x-wheel-enabled]="true"
        [snap-disabled]="true"
        (reachesRightBound)="storiesService.handleReachesRightBound($event)">
        <div
          drag-scroll-item
          class="carousel-preview__item"
          [ngClass]="{ active: !sliderItem.viewed }"
          *ngFor="let sliderItem of storiesService.stories$ | async; let i = index">
          <div class="carousel-preview__item-wrapper" (click)="selectImage(i)" [id]="'imagePreview' + i">
            <img *ngIf="sliderItem.imageUrl" class="thumbnail" [src]="sliderItem.imageUrl" alt="Stories" lazyLoad />
            <img
              *ngIf="sliderItem.thumbnailUrl"
              class="thumbnail"
              [src]="sliderItem.thumbnailUrl"
              alt="Stories"
              lazyLoad />
          </div>
        </div>
      </drag-scroll>
    </div>
  </div>
</div>
