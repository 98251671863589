<ng-container *ngIf="data.id">
  <div class="player" [style.backgroundImage]="'url(' + data.imageUrl + ')'">
    <div class="container">
      <h2 class="back-title">
        <div (click)="goToPlayers()">
          <span class="icon icon-Arrow-Left"></span>
        </div>
      </h2>
      <div class="player__data">
        <ng-container *ngIf="!isMobile">
          <div class="player__data-info">
            <ng-container *ngTemplateOutlet="playerDataList"></ng-container>
            <div class="item" *ngIf="(data.socialLinks | json) !== '{}'">
              <span class="label">Follow {{ data.player.username }}</span>
              <app-social-links *ngIf="data.socialLinks" [socialLinks]="data.socialLinks"></app-social-links>
            </div>
          </div>
        </ng-container>

        <div class="player__data-name">
          <h1>{{ data.player.username }}</h1>
          <h2>{{ data.player.name }}</h2>
          <div class="player__data-buttons" appClickedOutside (clickOutside)="closeDonatePopover()">
            <app-button
              (click)="openDonatePopover($event)"
              [buttonData]="openDonatePopoverBtn"
              *ngIf="data.wallets"></app-button>
            <app-donate-popover
              [messagePlayerId]="data.player.id"
              [wallets]="data.wallets"
              *ngIf="data.wallets && walletConnectService.isDonatePopoverOpen"></app-donate-popover>
            <app-button
              [buttonData]="wishlistBtn"
              [tooltip]="data.playerWishListUrl ? null : 'Coming soon'"
              (click)="goToWishlist(data.playerWishListUrl)"></app-button>
            <div class="send-message">
              <app-button [buttonData]="messagesBtn" (click)="openMessagesList($event)"></app-button>
              <span *ngIf="data.unreadMessages > 0" class="unread-count">{{ data.unreadMessages }}</span>

              <app-player-messages-list
                appClickedOutside
                (clickOutside)="closeMessagesList()"
                *ngIf="isMessagesVisible"
                [playerId]="data.player.id"
                (closeMessages)="closeMessagesList()"
                (openDonate)="openDonatePopover($event)"></app-player-messages-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isMobile">
    <div class="player-data-scroll">
      <drag-scroll [drag-scroll-y-disabled]="true" [scroll-x-wheel-enabled]="true" [snap-disabled]="true">
        <ng-container *ngTemplateOutlet="playerDataList"></ng-container>
      </drag-scroll>
      <div class="container" *ngIf="(data.socialLinks | json) !== '{}'">
        <div class="follow">
          <span>Follow {{ data.player.username }}</span>
          <app-social-links *ngIf="data.socialLinks" [socialLinks]="data.socialLinks"></app-social-links>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #playerDataList>
    <div class="item">
      <span class="label">Age</span>
      <span class="value">{{ data.age }} years</span>
    </div>
    <div class="item">
      <span class="label">Game tag</span>
      <span class="value">{{ data.player.username }}</span>
    </div>
    <div class="item">
      <span class="label">Tournament Prize </span>
      <span class="value">$ {{ data.tournamentPrize | thousandSeparator }}</span>
    </div>
    <div class="item">
      <span class="label">Game played</span>
      <span class="value">{{ data.player.game.name }}</span>
    </div>
    <div class="item">
      <span class="label">Position</span>
      <span class="value">{{ data.position }}</span>
    </div>
    <div class="item">
      <span class="label">Date joined GG</span>
      <span class="value">{{ data.dateJoinedGG | date: 'dd.MM.yyyy' }}</span>
    </div>
    <div class="item">
      <span class="label">Year turned pro</span>
      <span class="value">{{ data.yearTurnedPro }}</span>
    </div>
  </ng-template>

  <div class="bio">
    <div class="container">
      <div class="bio__data">
        <div class="bio__data-biography">
          <h3>Biography</h3>
          <div class="html-content" [innerHTML]="data.biography.description | safeHtml"></div>
        </div>
        <img [src]="data.biography.imageUrl" [alt]="data.player.username" lazyLoad />
      </div>
    </div>
  </div>
  <div class="honors">
    <div class="container">
      <h3>Honors</h3>
      <ng-container *ngIf="honors.length > 0">
        <div class="honors__data" *ngIf="!isMobile">
          <table>
            <tr>
              <th>Tournament</th>
              <th>Result</th>
              <th>Prize</th>
              <th>Date</th>
            </tr>
            <ng-container *ngFor="let honor of honors; let i = index">
              <tr *ngIf="showAll || i < 6">
                <td>
                  <div class="logo">
                    <img [src]="honor.avatarUrl" [alt]="honor.name" lazyLoad />
                    <span>{{ honor.name }}</span>
                  </div>
                </td>
                <td>
                  <div class="logo">
                    <img [src]="honor.team1.logoUrl" [alt]="honor.team1.name" lazyLoad />
                    <span>
                      {{ honor.team1.name }}
                      <ng-container *ngIf="isNumber(honor.team2.score) && honor.team2.score >= 0">
                        <span
                          [ngClass]="{
                            win: honor.team1.score >= honor.team2.score,
                            lose: honor.team1.score < honor.team2.score
                          }">
                          {{ honor.team1.score }}:{{ honor.team2.score }}
                        </span>
                        {{ honor.team2.name }}
                      </ng-container>
                      <ng-container *ngIf="!isNumber(honor.team2.score) && !honor.team2.score">
                        <span class="single">
                          {{ honor.team1.score }}
                        </span>
                      </ng-container>
                    </span>
                    <img *ngIf="honor.team2.logoUrl" [src]="honor.team2.logoUrl" [alt]="honor.team2.name" lazyLoad />
                  </div>
                </td>
                <td>$ {{ honor.prizePool | thousandSeparator }}</td>
                <td>{{ honor.finishDate | date: 'dd.MM.YYYY' }}</td>
              </tr>
            </ng-container>
            <tr *ngIf="honors.length > 6">
              <td colspan="4" class="see-more" (click)="toggleShowAll()">
                <div>
                  See {{ showAll ? 'less' : 'all' }}
                  <span class="icon-Arrow-Bottom" [ngClass]="{ active: showAll }"></span>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <mat-accordion [displayMode]="'flat'" *ngIf="isMobile">
          <ng-container *ngFor="let honor of honors; let i = index">
            <mat-expansion-panel *ngIf="showAll || i < 6">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <div class="logo">
                    <img [src]="honor.avatarUrl" [alt]="honor.name" lazyLoad />
                    <span>{{ honor.name }}</span>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ul>
                <li>
                  <span class="title">Result</span>
                  <div class="logo">
                    <img [src]="honor.team1.logoUrl" [alt]="honor.team1.name" lazyLoad />
                    <span>
                      {{ honor.team1.name }}
                      <ng-container *ngIf="honor.team2.score">
                        <span
                          [ngClass]="{
                            win: honor.team1.score >= honor.team2.score,
                            lose: honor.team1.score < honor.team2.score
                          }">
                          {{ honor.team1.score }}:{{ honor.team2.score }}
                        </span>
                        {{ honor.team2.name }}
                      </ng-container>
                      <ng-container *ngIf="!honor.team2.score">
                        <span class="single">
                          {{ honor.team1.score }}
                        </span>
                      </ng-container>
                    </span>
                    <img *ngIf="honor.team2.logoUrl" [src]="honor.team2.logoUrl" [alt]="honor.team2.name" lazyLoad />
                  </div>
                </li>
                <li>
                  <span class="title">Prize</span>
                  <div>$ {{ honor.prizePool | thousandSeparator }}</div>
                </li>
                <li>
                  <span class="title">Date</span>
                  <div>{{ honor.finishDate | date: 'dd.MM.YYYY' }}</div>
                </li>
              </ul>
            </mat-expansion-panel>
          </ng-container>
          <mat-expansion-panel *ngIf="honors.length > 6" class="see-more-row">
            <mat-expansion-panel-header (click)="toggleShowAll()">
              <mat-panel-title>
                <div class="see-more">
                  See {{ showAll ? 'less' : 'all' }}
                  <span class="icon-Arrow-Bottom" [ngClass]="{ active: showAll }"></span>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
      <app-empty *ngIf="honors.length === 0" [message]="'The player does not have any honors yet'"></app-empty>
    </div>
  </div>
  <div class="content">
    <div class="container">
      <h3>More about {{ data.player.username }}</h3>
    </div>
    <div class="container">
      <ul class="content__tabs">
        <li [class.active]="activeTab === 0" (click)="setActiveTab(0)">Moments</li>
        <li [class.active]="activeTab === 1" (click)="setActiveTab(1)">Videos</li>
        <li [class.active]="activeTab === 2" (click)="setActiveTab(2)">News feed</li>
      </ul>
    </div>
    <ng-container *ngIf="activeTab === 0"><app-stories [playerId]="data.player.id"></app-stories></ng-container>
    <ng-container *ngIf="activeTab === 1"><app-videos [playerId]="data.player.id"></app-videos></ng-container>
    <div class="container">
      <ng-container *ngIf="activeTab === 2"><app-articles [playerId]="data.player.id"></app-articles></ng-container>
    </div>
  </div>
</ng-container>
