<div
  class="claim-reward"
  [tooltip]="
    (twitchService.streamingRewardBalance$ | async)! < (twitchService.withdrawalOptions$ | async)!.minimumAmount
      ? 'The minimal withdrawal amount is ' + (twitchService.withdrawalOptions$ | async)?.minimumAmount
      : ''
  "
  [tooltipPosition]="'bottom'">
  <div class="claim-reward__balance">
    <div class="claim-reward__balance-layout">
      {{ twitchService.streamingRewardBalance$ | async | number: '1.2-2' }}
    </div>
    <img src="assets/icons/coins/GMRX.svg" alt="GMRX" lazyLoad/>
    <div class="progress-bar">
      <div></div>
    </div>
  </div>

  <app-button
    [buttonData]="claimBtnData"
    [attr.disabled]="
      !(twitchService.withdrawalOptions$ | async)?.minimumAmount ||
      (twitchService.streamingRewardBalance$ | async)! < (twitchService.withdrawalOptions$ | async)!.minimumAmount
    "
    (click)="openClaimRewardModal()"></app-button>
</div>
