<div class="youtube-player">
  <iframe
    *ngIf="!isBgVisible"
    width="100%"
    height="100%"
    src="https://customer-ud16g7q6qzl6soai.cloudflarestream.com/27f88928a5b74dc4888a80fe1087fd10/iframe?autoplay=true&muted=true"
    title="Gaimin Gladiators Community"
    allow="autoplay"
    allowfullscreen></iframe>
  <div *ngIf="isBgVisible" class="player-bg"><span class="icon-Play" (click)="showVideo()"></span></div>
</div>
