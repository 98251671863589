import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, BehaviorSubject, combineLatest, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { AuthService } from '../../shared/services/auth.service';
import { ContentService } from '../../shared/services/content.service';
import { AdditionalService } from '../../shared/services/additional.service';
import { WalletConnectService } from '../../shared/services/wallet-connect.service';
import { EventData, Button } from '../../shared/intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../shared/enums';

@Component({
  selector: 'app-events-claim-page',
  templateUrl: './events-claim-page.component.html',
  styleUrls: ['./events-claim-page.component.scss']
})
export class EventsClaimPageComponent implements OnInit, OnDestroy {
  @AutoUnsubscribe()
  private authSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private eventSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private claimSub: Subscription = {} as Subscription;
  @AutoUnsubscribe()
  private walletSub: Subscription = {} as Subscription;

  readonly claimBtn: Button = {
    name: 'Claim Digital Proof of Attendance',
    size: BUTTON_SIZE.AUTO
  };

  readonly connectBtn: Button = {
    name: 'Connect Wallet to receive DPA',
    size: BUTTON_SIZE.AUTO
  };

  readonly connectLaterBtn: Button = {
    name: 'Connect Wallet Later',
    size: BUTTON_SIZE.AUTO,
    type: BUTTON_TYPE.GREY
  };

  readonly profileBtn: Button = {
    name: 'Go to my GG Fan Profile',
    size: BUTTON_SIZE.AUTO
  };

  readonly homeBtn: Button = {
    name: 'Discover The Colosseum',
    size: BUTTON_SIZE.AUTO
  };

  id: string | null = null;
  token: string | null = null;
  eventData: EventData | null = null;
  isClaimed: boolean = false;
  isEmpty: boolean = false;

  isWalletConnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    public contentService: ContentService,
    public walletConnectService: WalletConnectService,
    private additionalService: AdditionalService,
    public authService: AuthService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit(): void {
    this.authService.redirectUrl = this.router.url;

    this.id = this.activatedRoute.snapshot.queryParamMap.get('id');
    this.token = this.activatedRoute.snapshot.queryParamMap.get('token');

    if (this.id) {
      this.eventSub = this.contentService.getEventInfoByIdRequest(this.id).subscribe((res) => {
        if (res.success && res.data) {
          this.eventData = res.data;
        } else {
          this.isEmpty = true;
        }
      });
    } else {
      this.isEmpty = true;
    }

    this.authSub = this.authService.isLoggedIn$.subscribe({
      next: (isLoggedIn) => {
        if (isLoggedIn) {
          this.authService.checkDpaEventEnrollment(false, false);
        }
      }
    });

    this.walletSub = combineLatest([
      this.authService.dpaEventEnrollmentData$,
      this.walletConnectService.isWalletConnected$
    ])
      .pipe(
        map(([dpaEventEnrollmentData, isWalletConnected]) => {
          return dpaEventEnrollmentData.isWalletConnected || isWalletConnected;
        })
      )
      .subscribe({
        next: (isWalletConnected) => {
          console.log('Combined value:', isWalletConnected);
          this.isWalletConnected$.next(isWalletConnected);
        }
      });

    if (this.activatedRoute.snapshot.queryParamMap.get('reconnectWallet')) {
      this.openWalletConnetPopup();
      this.router.navigate([], {
        queryParams: {
          reconnectWallet: null
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  openWalletConnetPopup() {
    this.walletConnectService.openModal();
  }

  claim() {
    if (this.id && this.token) {
      this.claimSub = this.contentService.claimDpaEventRequest(this.id, this.token).subscribe((res) => {
        if (res.success) {
          this.isClaimed = true;
        } else {
          this.toastrService.error((res as any).error.description);
          from(this.walletConnectService.disconnectFromWallet()).subscribe(() => {
            window.location.reload();
          });
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { reconnectWallet: true },
            queryParamsHandling: 'merge'
          });
        }
      });
    }
  }

  goToProfile() {
    this.toastrService.success('DPA will be seen here shortly after the event');
    this.router.navigateByUrl('profile');
  }

  goToContent() {
    this.router.navigateByUrl('/content');
  }

  goToSignIn() {
    this.authService.openLoginPopup(true, true);
  }

  ngOnDestroy() {
    this.authService.closeLoginPopup();
    this.authService.redirectUrl = null;
  }
}
