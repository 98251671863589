<div class="container">
  <div class="players">
    <h1 class="title">Players</h1>
    <div *ngFor="let group of contentService.playersListByGame$ | async | keyvalue" class="players__game">
      <h2 class="players__game-name">
        <img [src]="group.value.game.avatarUrl" [alt]="group.value.game.name" lazyLoad />
        <span>{{ group.value.game.name }}</span>
      </h2>
      <div class="players__list">
        <div
          *ngFor="let item of group.value.players"
          class="players__list-item"
          (click)="item.profileExists ? navigateTo(item.id) : null"
          (auxclick)="item.profileExists ? openInNewTab(item.id) : null">
          <div class="img-wrappper" [ngClass]="{ comingSoon: !item.profileExists }">
            <img [src]="item.avatarUrl" [alt]="item.username" lazyLoad />
          </div>
          <div class="players__list-item__info">
            <span class="players__list-item__info-username">{{ item.username }}</span>
            <span class="players__list-item__info-name">{{ item.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
