import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { TwitchService } from '../../shared/services/twitch.service';
import { AdditionalService } from '../../shared/services/additional.service';

@Component({
  selector: 'streams-page',
  templateUrl: './streams-page.component.html',
  styleUrls: ['./streams-page.component.scss']
})
export class StreamsPageComponent implements OnInit, OnDestroy {

  constructor(
    private additionalService: AdditionalService,
    private twitchService: TwitchService
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit(): void {
    this.twitchService.loadChannels();
  }

  ngOnDestroy() {
    this.twitchService.destroy();
  }
}
