<div class="container mobile-without-padding">
  <div class="content__banners">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let sliderItem of banners; let i = index">
        <ng-template carouselSlide [id]="i.toString()">
          <a [href]="sliderItem.externalUrl" target="_blank" rel="noopener noreferrer"
            ><img [src]="sliderItem.imageUrl" alt="The Colloseum" lazyLoad
          /></a>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
</div>
