<div class="filter" appClickedOutside (clickOutside)="closeAuthorsList()">
  <div class="filter-select" (click)="openAuthorsList()">
    Author
    <span
      class="title"
      *ngIf="
        authors.selectedOptions.selected.length <= 0 ||
          authors.selectedOptions.selected.length == (contentService.authorsList$ | async)!.length;
        else authorsList
      ">
      All
    </span>
    <ng-template #authorsList>
      <div class="authors">
        <ng-container *ngFor="let item of authors.selectedOptions.selected; let i = index">
          <ng-container *ngIf="i < 6">
            <img [src]="item.value.avatarUrl" alt="Author Avatar" lazyLoad />
          </ng-container>
          <ng-container *ngIf="i === 5 && authors.selectedOptions.selected.length > 6">
            <span>+{{ authors.selectedOptions.selected.length - 6 }}</span>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
    <span class="icon icon-Arrow-Bottom" [ngClass]="{ active: isAuthorsDropdownOpen$ | async }"></span>
  </div>
  <mat-selection-list
    #authors
    class="filter-dropdown"
    [ngClass]="{ active: isAuthorsDropdownOpen$ | async }"
    (selectionChange)="onAuthorsSelectionChange($event)">
    <mat-list-option
      *ngFor="let author of contentService.authorsList$ | async"
      color="primary"
      [value]="author"
      [selected]="selectedAuthorsIds.includes(author.id)">
      <img matListItemAvatar [src]="author.avatarUrl" [alt]="author.name" lazyLoad />
      <span matListItemTitle>{{ author.name }}</span>
    </mat-list-option>
  </mat-selection-list>
</div>
