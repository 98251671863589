<mat-dialog-content class="product-dialog">
  <div class="product">
    <div class="header">
      <span class="icon-Close close-icon" title="Close" (click)="closeModal()"></span>
    </div>
    <div class="product__item">
      <div class="product__item-images">
        <div class="product__item-images__sale">
          <img src="../../../../../assets/icons/packages/platinum.png" alt="Platinum" lazyLoad />
          Save 25%
        </div>
        <img
          class="product__item-images__img"
          onError="this.src = 'assets/icons/default-user-image.png'"
          [src]="imageSrc"
          alt="Product"
          lazyLoad />
        <drag-scroll [drag-scroll-y-disabled]="true" [scroll-x-wheel-enabled]="true" [snap-disabled]="true">
          <div
            drag-scroll-item
            class="product__item-images__preview"
            *ngFor="let sliderItem of productItem.images; let i = index">
            <img
              *ngIf="sliderItem.src"
              [src]="sliderItem.src"
              (click)="selectImage(sliderItem.src)"
              [id]="'imagePreview' + i"
              alt=""
              lazyLoad />
          </div>
        </drag-scroll>
      </div>

      <div class="product__item-info">
        <h2 class="product__item-info__title">{{ productItem.title }}</h2>
        <h3 class="product__item-info__subtitle">
          {{ productInfo?.productName }}
        </h3>

        <div class="product__item-info__divider"></div>

        <ul class="product__item-info__price">
          <ng-container *ngFor="let price of prices; let i = index">
            <li [ngClass]="{ active: price.active }" class="item{{ i }}">
              <span class="title">{{ price.active ? 'Your&nbsp;' + price.title.toLowerCase() : price.title }}</span>
              <span class="amount">
                <img [src]="'assets/icons/packages/' + price.type.toLowerCase() + '.png'" [alt]="price.type" lazyLoad />
                ${{ storeService.calculateNewPrice(productItem.variants[0].price, price.type) | number: '1.2-2' }}
                <span *ngIf="!price.active" (click)="goToPage(productItem.handle)" class="icon icon-Arrow-Right"></span>
              </span>
            </li>
          </ng-container>
        </ul>

        <div class="product__item-info__divider"></div>

        <h4 class="product__item-info__section">Size</h4>
        <div class="product__item-info__size">
          <span
            [class.active]="isActive(productItem.id, variant.id)"
            *ngFor="let variant of productItem.variants"
            (click)="toggleActive(productItem.id, variant.id)">
            {{ variant.title }}
          </span>
        </div>

        <div class="product__item-info__divider"></div>

        <h4 class="product__item-info__section">Quantity</h4>
        <div class="product__item-info__quantity">
          <div class="product__item-info__quantity-amount">
            <span class="icon-Minus" (click)="decreaseQuantity()"></span>
            <input type="number" [(ngModel)]="quantity" tabindex="-1" />
            <span class="icon-Plus" (click)="increaseQuantity()"></span>
          </div>
          <app-button [attr.disabled]="currentVariantId === 1" (click)="buyNow()" [buttonData]="buyNowBtn"></app-button>
        </div>

        <div class="product__item-info__divider"></div>

        <ng-container *ngIf="productInfo?.productName">
          <h4 class="product__item-info__section">Materials</h4>
          <p class="product__item-info__text">{{ (productInfo?.materials)![0] }} {{ (productInfo?.materials)![1] }}</p>
          <div class="product__item-info__divider"></div>
        </ng-container>

        <h4 class="product__item-info__section">Delivery</h4>
        <div class="product__item-info__delivery">
          <p class="product__item-info__text" [class.collapsed]="!showFullText">
            With a comprehensive list of countries where we can send parcels, you can familiarize yourself here. Under
            current conditions, shipments to Eastern Europe, the USA, and Europe arrive within 10-20 days, while to
            other countries, it takes 20-30 days.
            <span *ngIf="showFullText" (click)="showFullText = !showFullText"> See less </span>
          </p>
          <span *ngIf="!showFullText" (click)="showFullText = !showFullText"> See more </span>
        </div>

        <div class="product__item-info__divider"></div>

        <h4 class="product__item-info__section">Fees</h4>
        <p class="product__item-info__text">Duties</p>
      </div>
    </div>
  </div>
</mat-dialog-content>
