import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss']
})
export class TickerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('textWidthElement') textWidthElement!: ElementRef;
  @ViewChild('tickerContainer') tickerContainer!: ElementRef;

  repeatCounter = [...Array(4).keys()];
  textWidth: number = 0;
  margin = 0;
  interval: any;
  tickerSpeed = 15;
  tickerData = ['img1.svg', 'img2.svg', 'img3.svg', 'img4.svg', 'img5.svg', 'img6.svg'];
  imgPath = 'assets/icons/home/ticker/';

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.setTextWidthAndInitTicker();
    }, 3000);
  }

  setTextWidthAndInitTicker() {
    this.textWidth = this.textWidthElement.nativeElement.offsetWidth;
    this.start();
  }

  start() {
    let resetMargin = this.textWidth * -1;
    this.interval = setInterval(() => {
      this.tickerContainer.nativeElement.style.transform = 'translateX(' + this.margin-- + 'px)';
      if (this.margin < resetMargin) {
        this.margin = 0;
      }
    }, this.tickerSpeed);
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.margin = 0;
  }
}
