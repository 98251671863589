<div class="comments">
  <h2 class="sub-title">
    Comments <span class="count" *ngIf="comments.length > 0">{{ comments.length }}</span>
  </h2>

  <form [formGroup]="form" class="comments__form">
    <div class="comments__form-field">
      <input formControlName="message" placeholder="Add a comment" #messageInput />
      <div class="comments__form-field__reactions">
        <app-reactions-popover
          (selectReactionEvent)="onAddReactionToMessage($event)"
          [position]="popoverPosition"></app-reactions-popover>
      </div>
    </div>
    <app-button
      [buttonData]="submitDesktopBtn"
      [attr.disabled]="form.invalid"
      (click)="onAddComment()"
      class="hide-mobile"></app-button>
    <app-button
      [buttonData]="submitMobileBtn"
      [attr.disabled]="form.invalid"
      (click)="onAddComment()"
      class="hide-desktop"></app-button>
  </form>

  <app-filter-sorting
    [selectedSorting]="sortingOrder"
    (changeSorting)="onChangeSortingOrder($event)"></app-filter-sorting>

  <div class="comments__list">
    <div class="comments__list-item" *ngFor="let item of comments">
      <img
        class="comments__list-item__author"
        [src]="item.avatarUrl"
        alt="Author Avatar"
        onError="this.src = 'assets/icons/default-user-image.png'"
        lazyLoad />
      <div class="comments__list-item__data">
        <p>
          {{
            item.authorNickname && item.authorNickname !== '' ? item.authorNickname : item.authorName + item.authorId
          }}
          <span>{{ item.createdOn | customDate }}</span>
        </p>
        <div class="message">{{ item.message }}</div>
        <app-reactions-list
          [reactions]="item.reactions"
          [position]="popoverListPosition"
          (selectReactionEvent)="onSelectReaction($event, item.commentId)"></app-reactions-list>
      </div>

      <button
        class="icon-Dots-2 comments__list-item__actions"
        mat-button
        [matMenuTriggerFor]="menu"
        aria-label="Comment actions menu"
        *ngIf="
          item.authorId === (userService.userInfo$ | async)?.id ||
          authService.checkUserRights([USER_ROLES.ROLE_COLOSSEUM_MANAGER])
        "></button>

      <mat-menu #menu="matMenu" class="comments-menu">
        <button
          mat-menu-item
          (click)="onEditComment(item)"
          *ngIf="item.authorId === (userService.userInfo$ | async)?.id">
          <span class="icon-Edit icon"></span>
          <span class="comments-menu__text">Edit</span>
        </button>
        <button mat-menu-item (click)="onRemoveComment(item)">
          <span class="icon-Delete icon"></span>
          <span class="comments-menu__text">Delete</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
