<ng-container *ngIf="videosService.videos$ | async as videosSub">
  <div class="container">
    <div class="actions">
      <h3 *ngIf="contentPage">Videos</h3>
      <app-filter-authors [params]="params" (selectAuthors)="onAuthorsSelectionChange($event)"></app-filter-authors>
      <app-filter-sorting *ngIf="!contentPage" (changeSorting)="onChangeSortingOrder($event)"></app-filter-sorting>
      <a *ngIf="contentPage" class="actions__show-more" routerLink="/videos">All videos</a>
    </div>
  </div>
  <div class="container mobile-without-padding" *ngIf="videosSub.length > 0">
    <div class="videos">
      <ng-container *ngIf="isMobile; else videoGrid">
        <drag-scroll [drag-scroll-y-disabled]="true" [scroll-x-wheel-enabled]="true" [snap-disabled]="true">
          <ng-container *ngTemplateOutlet="videosList"></ng-container>
        </drag-scroll>
      </ng-container>

      <ng-template #videoGrid>
        <div class="videos__list">
          <ng-container *ngTemplateOutlet="videosList"></ng-container>
        </div>
      </ng-template>

      <ng-template #videosList>
        <div
          drag-scroll-item
          class="videos__item"
          [ngClass]="{ locked: !contentService.isContentVisible(item.tier) }"
          *ngFor="let item of videosSub; let i = index"
          (click)="contentService.isContentVisible(item.tier) ? navigateTo(item.id) : false"
          (auxclick)="contentService.isContentVisible(item.tier) ? openInNewTab(item.id) : false">
          <div class="videos__item-wrapper">
            <div class="video-wrapper">
              <img
                *ngIf="item.thumbnailUrl"
                class="thumbnail"
                [src]="item.thumbnailUrl"
                alt="Video Thumbnail"
                lazyLoad />
              <span *ngIf="item.videoUrl" class="play icon-Play"></span>
              <div *ngIf="!contentService.isContentVisible(item.tier)" (click)="goToPackages()" class="unlock">
                <span>Unlock</span>
                <span class="icon icon-Arrow-Down-Left"></span>
              </div>
              <div class="author">
                <img [src]="item.author.avatarUrl" [alt]="item.author.name" lazyLoad />
                <p>{{ item.author.name }}</p>
              </div>
            </div>
            <div class="info">
              <span class="name">{{ item.name }}</span>
              <span class="description">{{ item.description }}</span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="container" *ngIf="videosSub.length == 0">
    <div class="videos">
      <app-empty></app-empty>
    </div>
  </div>
</ng-container>
