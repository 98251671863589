<div class="container">
  <div class="article" *ngIf="data.id; else unauthorizedContentTemplate">
    <h2 class="back-title">
      <div (click)="additionalService.backClicked()">
        <span class="icon icon-Arrow-Left"></span>
      </div>
    </h2>

    <div class="article__data">
      <img *ngIf="data.imageUrl" [src]="data.imageUrl" [alt]="data.title" lazyLoad/>
      <div *ngIf="mediaVideoUrl" class="player">
        <iframe
          [title]="data.title"
          [src]="mediaVideoUrl"
          width="100%"
          height="100%"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"></iframe>
      </div>
      <div class="article__data-btns">
        <div class="article__data-btns__author">
          <img [src]="data.author.avatarUrl" [alt]="data.author.name" lazyLoad/>
          <p>{{ data.author.name }}</p>
        </div>
        <div class="article__data-btns__share-wrapper" appClickedOutside (clickOutside)="closeSharePopover()">
          <div class="article__data-btns__share" (click)="openSharePopover()">
            <span class="icon-Soc-Share"></span> Share
          </div>
          <app-share-popover
            *ngIf="isSharePopoverOpen"
            [metaTags]="unauthorizedContent"
            (closeEvent)="closeSharePopover()"></app-share-popover>
        </div>
      </div>
      <h1 class="article__data-title">{{ data.title }}</h1>
      <div *ngIf="data.text" class="article__data-text html-content" [innerHTML]="data.text | safeHtml"></div>
    </div>

    <app-reactions-list
      class="article__reactions"
      [reactions]="reactions"
      [position]="popoverListPosition"
      (selectReactionEvent)="onSelectReaction($event)"></app-reactions-list>
    <app-comments-list
      class="article__comments"
      [id]="data.id"
      [comments]="comments"
      [service]="articlesService"
      (changeSorting)="updateCommentsOrder($event)"></app-comments-list>
  </div>

  <ng-template #unauthorizedContentTemplate>
    <app-unauthorized-content
      *ngIf="unauthorizedContent && !loading"
      [content]="unauthorizedContent"
      [isAccessRestricted]="isAccessRestricted"></app-unauthorized-content>
  </ng-template>
</div>
