<div class="player" [class.collapsed]="!isChatOpen">
  <div id="twitchPlayer"></div>
  <span class="toggle-icon icon-Chat-Collapse" [class.collapsed]="!isChatOpen" (click)="toggleChatOpen()"> </span>
</div>
<div class="chat" *ngIf="(this.twitchService.currentChannel$ | async)?.channelName && isChatOpen">
  <iframe
    height="100%"
    title="twitchChat"
    loading="lazy"
    allow="autoplay; encrypted-media"
    [src]="
      'https://www.twitch.tv/embed/' +
      (this.twitchService.currentChannel$ | async)?.channelName +
        '/chat?parent=localhost&parent=colosseum.qa.gaimingladiators.gg&parent=colosseum.gaimingladiators.gg&darkpopout'
        | safeUrl
    "></iframe>
</div>
