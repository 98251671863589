<div class="channel" *ngIf="currentChannel$ | async as channelData">
  <h1 class="back-title">
    <div routerLink="..">
      <span class="icon icon-Arrow-Left"></span> {{ channelData.channelName }} stream
    </div>
  </h1>
  <div class="channel-header">
    <div class="channel-header__name">
      <img *ngIf="channelData.iconUrl" [src]="channelData.iconUrl" [alt]="channelData.channelName" lazyLoad />
      <h2>{{ channelData.streamerName }}</h2>
    </div>
    <app-streams-claim-reward></app-streams-claim-reward>
    <div class="channel-header__donate" appClickedOutside (clickOutside)="closeDonatePopover()">
      <app-button
        (click)="openDonatePopover()"
        [buttonData]="openDonatePopoverBtn"
        *ngIf="channelData.wallets"></app-button>
      <app-donate-popover
        [wallets]="channelData.wallets"
        *ngIf="walletConnectService.isDonatePopoverOpen"></app-donate-popover>
    </div>
  </div>

  <div class="channel-twitch">
    <twitch-player *ngIf="twitchChannelName"></twitch-player>
  </div>
</div>
<turnstile-captcha></turnstile-captcha>
