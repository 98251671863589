import { AfterViewInit, Component } from '@angular/core';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { TwitchService } from 'src/app/shared/services/twitch.service';

@Component({
  selector: 'twitch-player',
  templateUrl: './twitch-player.component.html',
  styleUrls: ['./twitch-player.component.scss']
})
export class TwitchPlayerComponent implements AfterViewInit {
  @AutoUnsubscribe()
  private currentChannelSub: Subscription = {} as Subscription;

  isChatOpen: boolean = true;

  constructor(public twitchService: TwitchService) { }

  toggleChatOpen(): void {
    this.isChatOpen = !this.isChatOpen;
  }

  ngAfterViewInit(): void {
    this.currentChannelSub = this.twitchService.currentChannel$.pipe(distinctUntilChanged((prev, next) => prev?.id === next?.id)).subscribe((channel) => {
      const twitchPlayerConfiguration = {
        width: '100%',
        height: '100%',
        channel: channel?.channelName,
        allowfullscreen: true,
        layout: 'video',
        muted: true,
        parent: ['localhost']
      };
      new Twitch.Player('twitchPlayer', twitchPlayerConfiguration);
    });
  }
}
