<ng-container *ngIf="storiesService.stories$ | async as storiesSub">
  <div class="container">
    <div class="actions">
      <h3 *ngIf="contentPage">Moments</h3>
      <app-filter-authors
        [params]="params"
        [selectedAuthorsIds]="storiesService.selectedAuthors"
        (selectAuthors)="onAuthorsSelectionChange($event)"></app-filter-authors>
      <app-filter-sorting *ngIf="!contentPage" (changeSorting)="onChangeSortingOrder($event)"></app-filter-sorting>
    </div>
  </div>
  <div class="container mobile-without-padding" *ngIf="storiesSub.length > 0">
    <div class="stories">
      <ng-container *ngIf="contentPage || isMobile; else storiesGrid">
        <drag-scroll [drag-scroll-y-disabled]="true" [scroll-x-wheel-enabled]="true" [snap-disabled]="true">
          <ng-container *ngTemplateOutlet="storiesList"></ng-container>
        </drag-scroll>
      </ng-container>

      <ng-template #storiesGrid>
        <div class="stories__list">
          <ng-container *ngTemplateOutlet="storiesList"></ng-container>
        </div>
      </ng-template>

      <ng-template #storiesList>
        <div
          drag-scroll-item
          class="stories__item"
          [ngClass]="{ active: !item.viewed, locked: !contentService.isContentVisible(item.tier) }"
          *ngFor="let item of contentPage ? (storiesSub | slice: 0 : 5) : storiesSub; let i = index"
          (click)="contentService.isContentVisible(item.tier) ? openStoriesPopup(i) : false">
          <div class="stories__item-wrapper">
            <img
              *ngIf="item.imageUrl"
              class="thumbnail"
              [ngClass]="{ fixedWidth: contentPage }"
              [src]="item.imageUrl"
              alt="Stories"
              lazyLoad />
            <img
              *ngIf="item.thumbnailUrl"
              class="thumbnail"
              [ngClass]="{ fixedWidth: contentPage }"
              [src]="item.thumbnailUrl"
              alt="Stories"
              lazyLoad />
            <div *ngIf="!contentService.isContentVisible(item.tier)" (click)="goToPackages()" class="unlock">
              <span>Unlock</span>
              <span class="icon icon-Arrow-Down-Left"></span>
            </div>
            <div class="author">
              <img [src]="item.author.avatarUrl" [alt]="item.author.name" lazyLoad />
              <p>{{ item.author.name }}</p>
            </div>
          </div>
        </div>
        <div *ngIf="contentPage" drag-scroll-item class="stories__item" (click)="openStoriesPopup(0)">
          <div class="stories__item-wrapper">
            <div class="view-more">
              <span class="icon icon-View-More"></span>
              <span class="text">View more</span>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="container" *ngIf="storiesSub.length == 0">
    <div class="stories">
      <app-empty></app-empty>
    </div>
  </div>
</ng-container>
