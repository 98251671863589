import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AdditionalService } from '../../../../shared/services/additional.service';
import { TwitchService } from '../../../../shared/services/twitch.service';
import { WalletConnectService } from '../../../../shared/services/wallet-connect.service';
import { Button } from '../../../../shared/intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../../shared/enums';
import { debounceTime, filter, skip, startWith, switchMap, take, tap } from 'rxjs/operators';
import { TurnstileService } from '../../../../shared/services/turnstile.service';

@Component({
  selector: 'app-channel-page',
  templateUrl: './channel-page.component.html',
  styleUrls: ['./channel-page.component.scss']
})
export class ChannelPageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  private isBrowserVisibleSrc = new BehaviorSubject<boolean>(false);
  public isBrowserVisible$ = this.isBrowserVisibleSrc.pipe(skip(1), startWith(true), debounceTime(1000));

  twitchChannelName: string = '';
  currentChannel$ = this.twitchService.currentChannel$;
  channelId?: number;

  private wasLive = false;
  private wasBrowserVisible = true;

  isChatOpen: boolean = true;

  readonly openDonatePopoverBtn: Button = {
    name: 'Donate',
    size: BUTTON_SIZE.SM,
    type: BUTTON_TYPE.FILLED,
    iconLeft: 'icon-donate'
  };

  constructor(
    public additionalService: AdditionalService,
    public twitchService: TwitchService,
    public walletConnectService: WalletConnectService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public turnstileService: TurnstileService
  ) {}

  @HostListener('document:visibilitychange')
  onVisibilityChange(): void {
    this.isBrowserVisibleSrc.next(document.visibilityState !== 'hidden');
  }

  ngOnInit(): void {
    let paramsSub = this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.channelId = +params.get('channel')!;
      this.twitchService.setCurrentChannelId(this.channelId);
    });

    let currentChannelSub = combineLatest([
      this.currentChannel$.pipe(filter(Boolean)),
      this.isBrowserVisible$
    ]).subscribe(([channel, isBrowserVisible]) => {
      this.twitchChannelName = channel.channelName;

      // start monetization session
      if ((isBrowserVisible && !this.wasBrowserVisible) || (channel.isLive && !this.wasLive)) {
        this.wasBrowserVisible = isBrowserVisible;
        this.wasLive = !!channel.isLive;
        this.refreshTokenAndStartSession();
        return;
      // stop monetization session (backend not stopping it for some reason)
      } else if ((!isBrowserVisible && this.wasBrowserVisible) || (!channel.isLive && this.wasLive)) {
        this.wasBrowserVisible = isBrowserVisible;
        this.wasLive = !!channel.isLive;
        // update session before timer should stop the session
        this.refreshTokenAndUpdateSession();
        this.twitchService.stopCollectingReward();
        return;
      }
    });

    this.subscriptions.add(paramsSub);
    this.subscriptions.add(currentChannelSub);
  }

  refreshTokenAndStartSession(): void {
    this.turnstileService
      .refreshToken()
      .pipe(
        take(1),
        tap(() => {
          this.twitchService.startCollectingReward();
        })
      )
      .subscribe();
  }

  refreshTokenAndUpdateSession(): void {
    this.turnstileService
      .refreshToken()
      .pipe(
        take(1),
        switchMap(() => {
          return this.twitchService.updateWatchingSessionRequest();
        })
      )
      .subscribe();
  }

  openDonatePopover() {
    if (this.walletConnectService.isWalletConnected$.getValue()) {
      this.walletConnectService.isDonatePopoverOpen = !this.walletConnectService.isDonatePopoverOpen;
    } else {
      this.toastrService.success('Please connect your wallet to make a donation!');
    }
  }

  closeDonatePopover() {
    this.walletConnectService.isDonatePopoverOpen = false;
  }

  toggleChatOpen() {
    this.isChatOpen = !this.isChatOpen;
  }

  ngOnDestroy(): void {
    this.twitchService.stopCollectingReward();
    this.subscriptions?.unsubscribe();
  }
}
