import { Injectable, Renderer2 } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, filter, map, Observable } from 'rxjs';
import { FAN_AREA_ITEMS } from 'src/app/shared/enums';
import { NotificationListComponent } from 'src/app/shared/components/notification/notification-list/notification-list.component';
import { ReferralsSectionComponent } from 'src/app/shared/components/referrals/referrals-section/referrals-section.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { LeaderboardSectionComponent } from 'src/app/shared/components/leaderboard/leaderboard-section/leaderboard-section.component';
import { NotificationService } from 'src/app/shared/services/notification.service';

interface FloatingMenuItem {
  id: FAN_AREA_ITEMS;
  title: string;
  icon: string;
  badge?: Observable<boolean>;
  active?: boolean;
  component: any;
  pagePath?: string;
}

@Injectable()
export class FloatingMenuService {
  private renderer: Renderer2 | null = null;

  private menuItemsSrc = new BehaviorSubject<FloatingMenuItem[]>([
    // {
    //   id: FAN_AREA_ITEMS.LEADERBOARD,
    //   title: 'Leaderboard',
    //   icon: 'trophy.svg',
    //   component: LeaderboardSectionComponent,
    //   pagePath: 'fan-area/leaderboard'
    // },
    // {
    //   id: FAN_AREA_ITEMS.REFERRALS,
    //   title: 'Invite Friends',
    //   icon: 'referral.svg',
    //   component: ReferralsSectionComponent,
    //   pagePath: 'fan-area/referrals'
    // },
    {
      id: FAN_AREA_ITEMS.NOTIFICATION,
      title: 'Notifications',
      badge: this.notificationService.hasNewNotifications$,
      icon: 'bell.svg',
      component: NotificationListComponent
    },
  ]);

  menuItems$ = this.menuItemsSrc.asObservable();
  activeMenuItem$ = this.menuItems$.pipe(map((items) => items.find((item) => item.active)));
  isDrawerOpen$ = this.activeMenuItem$.pipe(map((item) => !!item));

  constructor(
    private toastrService: ToastrService,
    public notificationService: NotificationService,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    combineLatest([this.breakpointObserver.observe(['(max-width: 768px)']), this.activeMenuItem$])
      .pipe(filter(([state, activeItem]) => !!activeItem && state.matches))
      .subscribe(([state, activeItem]) => {
        this.closeDrawer();

        if (activeItem?.pagePath) {
          this.router.navigateByUrl(activeItem.pagePath);
        }
      });
  }

  setRenderer(renderer: Renderer2) {
    this.renderer = renderer;
  }

  openDrawer(id: FAN_AREA_ITEMS) {
    this.toastrService.clear();
    this.renderer?.setStyle(document.body, 'overflow', 'hidden');

    const updatedMenu = this.menuItemsSrc.value.map((item) => ({ ...item, active: item.id === id }));
    this.menuItemsSrc.next(updatedMenu);
  }

  closeDrawer() {
    this.renderer?.removeStyle(document.body, 'overflow');
    this.menuItemsSrc.next(this.menuItemsSrc.value.map((item) => ({ ...item, active: false })));
  }
}
