import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { TurnstileService } from '../../services/turnstile.service';

@Component({
  selector: 'turnstile-captcha',
  template: '<div id="turnstile-captcha" #turnstileCaptcha></div>'
})
export class TurnstileCaptchaComponent implements AfterViewInit, OnDestroy {
  @ViewChild('turnstileCaptcha') turnstileCaptcha!: ElementRef;

  constructor(private turnstileService: TurnstileService) {}

  ngAfterViewInit() {
    this.turnstileService.initTurnstileCaptcha(this.turnstileCaptcha);
  }

  ngOnDestroy(): void {
    this.turnstileService.destroyCaptcha();
  }
}
