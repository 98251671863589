import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, catchError, filter, Observable, of, Subject, switchMap, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TurnstileService {
  private widgetId$ = new BehaviorSubject<string | null>(null);
  private tokenSubject = new Subject<string>();

  turnstileToken$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  initTurnstileCaptcha(elRef: ElementRef) {
    try {
      turnstile.ready(() => {
        const widgetId = turnstile.render(elRef.nativeElement, {
          sitekey: environment.cloudflareTurnstileSiteKey,
          callback: (token: string) => {
            this.turnstileToken$.next(token);
            this.tokenSubject.next(token);
          },
          'expired-callback': () => this.refreshToken(),
          'error-callback': (errorMsg: string) => {
            console.error('Turnstile error:', errorMsg);
          },
          'refresh-expired': 'auto'
        });

        this.widgetId$.next(widgetId);
      });
    } catch (initializationError) {
      console.error('Error initializing Turnstile widget:', initializationError);
    }
  }

  refreshToken(): Observable<string> {
    return this.widgetId$.pipe(
      filter((widgetId) => !!widgetId),
      take(1),
      tap((widgetId) => turnstile.reset(widgetId)),
      switchMap(() => {
        return this.tokenSubject.asObservable();
      }),
      catchError(() => {
        throw 'Error refreshing Turnstile token: Widget ID is not defined';
      })
    );
  }

  /**
   * Resets the Turnstile widget (useful for cleanup).
   */
  destroyCaptcha(): void {
    if (this.widgetId$.value) {
      turnstile.reset(this.widgetId$.value);
      this.widgetId$.next(null);
      this.turnstileToken$.next(null);
    }
  }
}
