<div class="container">
  <div class="event" *ngIf="eventData">
    <div class="event__img" [class]="{ claimed: isClaimed }">
      <div class="event__img-wrapper">
        <img *ngIf="eventData.authorImageUrl" [src]="eventData.authorImageUrl" [alt]="eventData.title" lazyLoad />
      </div>
      <span *ngIf="isClaimed && (isWalletConnected$ | async)" class="icon-Available"></span>
    </div>
    <h1 class="event__title">{{ eventData.title }}</h1>
    <h2 class="event__description" *ngIf="!isClaimed">Claim Digital Proof of Attendance</h2>
    <h2 class="event__description" *ngIf="isClaimed && !(isWalletConnected$ | async)">
      Congratulations! You just claimed your Digital Proof of Attendance (DPA).
    </h2>
    <h2 class="event__description" *ngIf="isClaimed && (isWalletConnected$ | async)">
      Congratulations! You have a connected wallet. Your DPA will be delivered to your last connected wallet after the
      event!
    </h2>

    <div class="event__btn">
      <app-button
        *ngIf="!isClaimed && (authService.isLoggedIn$ | async)"
        [buttonData]="claimBtn"
        (click)="claim()"></app-button>
      <app-button
        *ngIf="!isClaimed && !(authService.isLoggedIn$ | async)"
        [buttonData]="claimBtn"
        (click)="goToSignIn()"></app-button>
      <app-button
        *ngIf="isClaimed && !(isWalletConnected$ | async)"
        [buttonData]="connectBtn"
        (click)="openWalletConnetPopup()"></app-button>
      <app-button
        *ngIf="isClaimed && !(isWalletConnected$ | async)"
        [buttonData]="connectLaterBtn"
        (click)="goToContent()"></app-button>
      <app-button
        *ngIf="isClaimed && (isWalletConnected$ | async)"
        [buttonData]="profileBtn"
        (click)="goToProfile()"></app-button>
    </div>
  </div>

  <ng-container *ngIf="isEmpty">
    <div class="event">
      <div class="event__img-empty"><img src="assets/icons/events/blur.svg" alt="Event" lazyLoad/></div>
      <h2 class="event__title">Event is not found</h2>
      <div class="event__btn">
        <app-button [buttonData]="homeBtn" (click)="goToContent()"></app-button>
      </div>
    </div>
  </ng-container>
</div>
