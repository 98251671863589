import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoginModalComponent } from '../components/modals/login-modal/login-modal.component';
import { OnboardingModalComponent } from '../components/modals/onboarding-modal/onboarding-modal.component';
import {
  ChangePasswordRequest,
  LoginResponse,
  UserSignInCredentials,
  UserSignUpCredentials,
  Response,
  SubscriptionTypeInfo,
  TokensResponse,
  DpaEventEnrollmentData,
  UtmSourceRequest,
  UtmSourceResponse
} from '../intarfaces';
import { environment } from '../../../environments/environment';
import { COLOSSEUM_TIERS, USER_ROLES } from '../enums';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  redirectUrl: string | null = null;

  currentUserRoles$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  managerEncodedCreds$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  acquireUserSubscriptionData$: BehaviorSubject<SubscriptionTypeInfo | null> =
    new BehaviorSubject<SubscriptionTypeInfo | null>({} as SubscriptionTypeInfo);
  dpaEventEnrollmentData$: BehaviorSubject<DpaEventEnrollmentData> = new BehaviorSubject<DpaEventEnrollmentData>(
    {} as DpaEventEnrollmentData
  );

  set accessToken(credentials: string) {
    localStorage.setItem('accessToken', credentials);
  }

  get accessToken(): string {
    return localStorage.getItem('accessToken') ? localStorage.getItem('accessToken')!.toString() : '';
  }

  set refreshToken(credentials: string) {
    localStorage.setItem('refreshToken', credentials);
  }

  get refreshToken(): string {
    return localStorage.getItem('refreshToken') ? localStorage.getItem('refreshToken')!.toString() : '';
  }

  set userRoles(userRoles: string) {
    localStorage.setItem('userRoles', userRoles);
  }

  get userRoles(): string {
    return localStorage.getItem('userRoles') ? localStorage.getItem('userRoles')!.toString() : '';
  }

  set managerToken(managersAuth: string) {
    localStorage.setItem('managerToken', managersAuth);
  }

  get managerToken(): string {
    return localStorage.getItem('managerToken') ? localStorage.getItem('managerToken')!.toString() : '';
  }

  set utmSourceId(utmSourceId: string) {
    localStorage.setItem('utmSourceId', utmSourceId);
  }

  get utmSourceId(): string {
    return localStorage.getItem('utmSourceId') ? localStorage.getItem('utmSourceId')!.toString() : '';
  }

  set invitingUserId(invitingUserId: string) {
    localStorage.setItem('invitingUserId', invitingUserId);
  }

  get invitingUserId(): string {
    return localStorage.getItem('invitingUserId') ? localStorage.getItem('invitingUserId')!.toString() : '';
  }

  constructor(
    private http: HttpClient,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public oktaAuthStateService: OktaAuthStateService,
    private dialogService: MatDialog,
    private overlay: Overlay,
    private toastrService: ToastrService,
    private router: Router
  ) {
    if (this.refreshToken) {
      this.isLoggedIn$.next(true);
    } else {
      this.oktaAuthStateService.authState$.subscribe((oktaAuthState) => {
        console.log('oktaAuthState:', oktaAuthState);
        if (!!oktaAuthState.isAuthenticated) {
          this.accessToken = oktaAuthState.accessToken!.accessToken;
          this.refreshToken = oktaAuthState.refreshToken!.refreshToken;
          this.checkEmptyProfileInfo();
          this.checkUtmLink();
          this.checkDpaEventEnrollment(true, false);
        }
        this.isLoggedIn$.next(!!oktaAuthState.isAuthenticated);
      });
    }

    this.isLoggedIn$.subscribe({
      next: (isLoggedIn) => {
        console.log('isLoggedIn', isLoggedIn);
      }
    });

    this.userRoles ? this.currentUserRoles$.next([this.userRoles]) : null;
  }

  successAuthorize(tokenData: TokensResponse, showOnboardingPopup: boolean = false, shouldRedirect: boolean = true) {
    this.refreshToken = tokenData.refreshToken;
    this.accessToken = tokenData.accessToken;
    this.isLoggedIn$.next(true);

    if (showOnboardingPopup) {
      this.dialogService.open(OnboardingModalComponent, {
        panelClass: 'dialog-overlay-pane'
      });
    }

    if (shouldRedirect) {
      this.router.navigateByUrl('/content');
    }
  }

  acquireAccount(showSuccessPopup: boolean = false, tier?: COLOSSEUM_TIERS) {
    this.acquireUserSubscriptionRequest(tier!).subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.acquireUserSubscriptionData$.next(response.data);
          if (showSuccessPopup) {
            this.toastrService.success(`Your account has been successfully changed to ${response.data.tier}`);
          }
          window.location.reload();
        }
      }
    });
  }

  checkEmptyProfileInfo() {
    this.checkEmptyProfileInfoRequest().subscribe({
      next: (response) => {
        if (response.success && !response.data) {
          this.dialogService.open(OnboardingModalComponent, {
            panelClass: 'dialog-overlay-pane'
          });
        }
      }
    });
  }

  checkUtmLink() {
    if (this.utmSourceId !== '') {
      this.utmSourceIdRequest({ utmSourceId: this.utmSourceId }).subscribe({
        next: (res) => {
          if (res.success) {
            localStorage.removeItem('utmSourceId');
          }
        }
      });
    }
  }

  checkEmptyProfileInfoRequest(): Observable<Response<boolean>> {
    return this.http.get<Response<boolean>>(environment.gaiminApi + '/users/me/empty-profile-info');
  }

  getUserSubscriptionTypeRequest(): Observable<Response<SubscriptionTypeInfo>> {
    return this.http.get<any>(environment.gaiminApi + '/colosseum-subscription/active');
  }

  updateSubscriptionInfo() {
    this.getUserSubscriptionTypeRequest().subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.acquireUserSubscriptionData$.next(response.data);
        }
      }
    });
  }

  acquireUserSubscriptionRequest(tier: COLOSSEUM_TIERS): Observable<Response<SubscriptionTypeInfo>> {
    return this.http.post<Response<SubscriptionTypeInfo>>(
      `${environment.gaiminApi}/colosseum-subscription/acquire?tier=${tier}`,
      {}
    );
  }

  unsubscribeSubscription(): Observable<Response<any>> {
    return this.http.post<Response<any>>(
      `${environment.gaiminApi}/colosseum-subscription/unsubscribe`,
      {}
    );
  }

  utmSourceIdRequest(data: UtmSourceRequest): Observable<Response<UtmSourceResponse>> {
    return this.http.post<Response<UtmSourceResponse>>(`${environment.gaiminApi}/users/utm`, data);
  }

  checkDpaEventEnrollment(showConnectWalletToastr: boolean = false, showSuccessToastr: boolean = false) {
    this.dpaEventEnrollmentRequest().subscribe({
      next: (response) => {
        if (response.success && response.data) {
          this.dpaEventEnrollmentData$.next(response.data);
          const url = this.router.url;
          if (!url.includes('events/dpa-claim')) {
            if (response.data.hasDpaClaims && !response.data.isWalletConnected) {
              if (showConnectWalletToastr) {
                this.toastrService.success(
                  `You’ve already claimed your DPA! Please connect your wallet to receive it.`,
                  undefined,
                  {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false
                  }
                );
              }
              if (showSuccessToastr) {
                this.toastrService.success(
                  `Congratulations, your wallet is connected successfully. Your DPA will appear in your Fan Passport after the
              event!`,
                  undefined,
                  {
                    timeOut: 0,
                    extendedTimeOut: 0,
                    tapToDismiss: false
                  }
                );
              }
            }
          }
        }
      }
    });
  }

  sendLoginRequest(userData: UserSignInCredentials): Observable<Response<LoginResponse>> {
    userData.fromColosseum = true;
    return this.http.post<Response<LoginResponse>>(`${environment.gaiminApi}/users/auth`, userData);
  }

  sendRegisterRequest(userData: UserSignUpCredentials): Observable<Response<TokensResponse>> {
    userData.fromColosseum = true;
    userData.invitingUserId = this.invitingUserId;
    return this.http.post<Response<TokensResponse>>(`${environment.gaiminApi}/users/register`, userData).pipe(
      tap((response) => {
        if (response.success && response.data) {
          localStorage.removeItem('invitingUserId');
        }
      }),
    );
  }

  refreshTokenRequest(): Observable<Response<TokensResponse>> {
    const refreshToken: string = this.refreshToken;
    console.log('Make refresh token request...');
    return this.http.post<Response<TokensResponse>>(`${environment.gaiminApi}/users/auth/refresh`, { refreshToken });
  }

  changePasswordRequest(newUserData: ChangePasswordRequest): Observable<Response<any>> {
    return this.http.post<Response<any>>(`${environment.gaiminApi}/users/me/change-password`, newUserData);
  }

  dpaEventEnrollmentRequest(): Observable<Response<DpaEventEnrollmentData>> {
    return this.http.get<Response<DpaEventEnrollmentData>>(`${environment.gaiminApi}/dpa-event/user-enrollment`);
  }

  loginWithOkta() {
    const redirectUrl = this.redirectUrl ? this.redirectUrl : '/content';
    this.oktaAuth.signInWithRedirect({ originalUri: redirectUrl }).then((signInResult) => {
      console.log('Okta sign in result', signInResult);
    });
  }

  async logout() {
    await this.oktaAuth.isAuthenticated().then(async (isAuthenticated) => {
      if (isAuthenticated) {
        await this.oktaAuth.signOut();
      }
      this.clearData();
      this.router.navigateByUrl('/');
    });
  }

  clearData() {
    this.isLoggedIn$.next(false);
    this.accessToken = '';
    this.refreshToken = '';
    this.managerToken = '';
    this.userRoles = USER_ROLES.ROLE_USER;
    this.acquireUserSubscriptionData$.next(null);
  }

  openLoginPopup(isSignUp: boolean = false, isFullScreen: boolean = false) {
    this.dialogService.open(LoginModalComponent, {
      panelClass: ['dialog-overlay-pane', isFullScreen ? 'full-screen' : ''],
      scrollStrategy: this.overlay.scrollStrategies.block(),
      data: {
        isSignUp,
        isFullScreen
      }
    });
  }

  closeLoginPopup() {
    this.dialogService.closeAll();
  }

  checkUserRights(userRoles: string[]) {
    return userRoles.some((roleToCheck) => this.currentUserRoles$.getValue().includes(roleToCheck));
  }

  getUserPermissions(credential: unknown): Observable<Response<USER_ROLES[]>> {
    const { email, password } = credential as { email: string; password: string };
    const credentials = `${email}:${password}`;
    const encodedCredentials = btoa(credentials);
    this.managerEncodedCreds$.next(encodedCredentials);
    this.managerToken = encodedCredentials;
    return this.http.get<Response<USER_ROLES[]>>(`${environment.gaiminApi}/admin/roles`, {
      headers: new HttpHeaders({
        Authorization: `Basic ${encodedCredentials}`
      })
    });
  }
}
