<app-home-header></app-home-header>
<div class="home-wide-container">
  <div class="packages">
    <h2 class="back-title">
      <div routerLink="/">
        <span class="icon icon-Arrow-Left"></span>
      </div>
    </h2>
    <h1 class="title">Packages</h1>
    <h2 class="sub-title">Package Features & Benefits List</h2>
    <app-packages [isLevelsVisible]="true"></app-packages>
    <h2 class="sub-title">Activation and Subscription on the Colosseum</h2>
    <div class="packages__description">
      <div class="html-content">
        <h3>Activation Mechanism</h3>
        <p>
          The activation fee on the Colosseum is a one-time fee that you pay every year (365 days) to activate your
          account to be eligible for features and benefits of the Colosseum. However, your first year of receiving
          premium features and benefits on the Colosseum is FREE from this fee as you don’t pay the activation fee for
          the first 365 days after joining the Colosseum on a premium membership.
        </p>
        <p>
          It’s important to also note that to enjoy the premium ‘goodies’ on the Colosseum, you need to do that under a
          plan (package). You must choose a premium package and “subscribe” to its features and benefits by locking the
          specified amount of $GMRX tokens.
        </p>
        <h3>Subscription Mechanism</h3>
        <p>
          Subscription on the Colosseum works in a way that involves fans choosing their desired package of features &
          benefits and locking the specified amount of $GMRX to enjoy these perks during the subscription period. What
          makes the Colosseum’s subscription mechanism special is that it isn’t a payment unlike most platforms; you
          stake the GAIMIN Fan token ($GMRX) which you might already be holding as a passionate Gaimin Gladiators fan.
        </p>
        <p>
          As long as your $GMRX tokens remain locked in a particular package on the Colosseum, also known as the
          Subscription Period, you will continue enjoying the perks associated with that package. As for the
          subscription period, each package’s subscription period is divided into a period of Fixed period of Lock and a
          Flexible period of Lock.
        </p>
        <p>
          When you choose a package and subscribe to it by locking the required $GMRX tokens, the first 180 days will be
          the period of Fixed Lock during which you can’t unstake your tokens nor downgrade to a lower package at any
          time during this period. However, you can still upgrade to a higher plan during this period and the 180-day
          countdown won’t be reset during this period.
        </p>
        <p>
          After 180 days of your Fixed Lock, you enter the indefinite period of Flexible Lock. This phase has no
          duration as long as your tokens remain locked in that package and during this period, you are free to upgrade
          and downgrade to any package as you please. However, changing your plan during this period, whether upgrading
          or downgrading, restarts the subscription period cycle for your new plan, starting with the 180-day Fixed Lock
          period and then the Flexible Lock.
        </p>
        <br />
        <p>
          For more info about Activation and Subscription on the Colosseum, read our <a routerLink="/faqs">FAQs</a>.
        </p>
      </div>
    </div>
  </div>
</div>
<app-home-footer></app-home-footer>
