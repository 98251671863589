import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TwitchService } from '../../../shared/services/twitch.service';
import { LoaderService } from '../../../shared/services/loader.service';
import { Channel } from 'src/app/shared/intarfaces';

@Component({
  selector: 'app-channel-list',
  templateUrl: './channel-list.component.html',
  styleUrls: ['./channel-list.component.scss']
})
export class ChannelListComponent {

  channels$ = this.twitchService.channelsList$;

  constructor(
    public twitchService: TwitchService,
    private router: Router,
    public loaderService: LoaderService
  ) {}

  navigateTo(channel: Channel) {
    this.router.navigateByUrl(`/streams/${channel.id}`);
  }

  trackChannel(index: number, item: any): string {
    return item.id && item.isLive;
  }
}
