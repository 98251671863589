<mat-accordion [displayMode]="'flat'">
  <mat-expansion-panel id="item1" (opened)="setPanelOpenState('item1')" (afterExpand)="scrollToExpanded('item1')">
    <mat-expansion-panel-header>
      <mat-panel-title>How do I become a member (sign up) on The Colosseum?</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState == 'item1'">
      <ol>
        <li>
          Visit The Colosseum Homepage:
          <a href="https://colosseum.gaimingladiators.gg/" target="_blank" rel="noopener noreferrer"
            >https://colosseum.gaimingladiators.gg/</a
          >
        </li>
        <li>
          Click the “Become a Member” button at either the top right or at the body of page
          <img src="assets/icons/faqs/become_member_1.png" alt="Become a Member" lazyLoad />
        </li>
        <li>
          You will be directed to choose your preferred package. Read through the details and benefits of each available
          package and choose your preferred one by clicking “Start”.
          <img src="assets/icons/faqs/become_member_2.png" alt="Become a Member" lazyLoad />
        </li>
        <li>
          Fill in your details. If you prefer other means of signing up, you can also do so via the likes of Google and
          Discord.
          <img src="assets/icons/faqs/become_member_3.png" alt="Become a Member" class="vertical" lazyLoad />
        </li>
        <li>
          After successfully creating an account and subscribing to your preferred package, you will be directed to The
          Colosseum!
          <img src="assets/icons/faqs/become_member_4.png" alt="Become a Member" lazyLoad />
        </li>
      </ol>
      <app-infographics [infographics]="infographics['item1']"></app-infographics>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item2" (opened)="setPanelOpenState('item2')" (afterExpand)="scrollToExpanded('item2')">
    <mat-expansion-panel-header>
      <mat-panel-title>How do I create a Web3 wallet?</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState == 'item2'">
      <p>
        To interact on The Colosseum using $GMRX tokens, you would need a Web3 wallet. We recommend the
        <strong>Venly</strong> wallet in this case but you can choose any BEP-20 compatible wallet.
      </p>
      <h2>How to create a Venly Wallet</h2>
      <ol>
        <li>
          Visit
          <a href="https://wallet.venly.io/" target="_blank" rel="noopener noreferrer">https://wallet.venly.io/</a>
        </li>
        <li>Press "Create Account"</li>
        <li>Select the sign-up method of your choice</li>
        <li>If you signed up via email, you will receive a code, enter this code when asked to provide it</li>
        <li>
          Choose a PIN and enter it twice
          <p>
            (!) Make sure to write this PIN down! It's very important to be able to access your account. If you lose it
            you will need to request a recovery PIN and this can take a few days since it's a manual process for the
            Venly security team.
          </p>
        </li>
        <li>You are logged in</li>
        <li>Press the type of wallet you prefer</li>
        <li>Press "Create new wallet"</li>
        <li>Enter your PIN from step 5</li>
        <li>Voilà, a wallet has been created</li>
      </ol>
      <h2>Additional Wallet Tips</h2>
      <ul>
        <li>Keep your seed phrase safe in a secure location, preferably offline.</li>
        <li>Consider using a password manager to securely store your wallet password.</li>
      </ul>
      <app-infographics [infographics]="infographics['item2']"></app-infographics>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item3" (opened)="setPanelOpenState('item3')" (afterExpand)="scrollToExpanded('item3')">
    <mat-expansion-panel-header>
      <mat-panel-title>How to setup your Colosseum account</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState == 'item3'">
      <p>
        A complete setup Colosseum profile consists of your full name; first & last, profile image, username, connected
        Web3 wallet, and Discord account; in addition to your email and phone number.
      </p>
      <img src="assets/icons/faqs/profile_1.png" alt="Profile" class="vertical" lazyLoad />
      <p>To setup your profile on the Colosseum:</p>
      <ol>
        <li>
          You must be logged in to your account; Sign Up if you don’t have an account.
          <img src="assets/icons/faqs/profile_2.png" alt="Profile" class="vertical" lazyLoad />
        </li>
        <li>
          In the Colosseum; click the ‘Profile’ Icon and then navigate to “My Account”
          <img src="assets/icons/faqs/profile_3.png" alt="Profile" class="vertical" lazyLoad />
        </li>
        <li>
          If you are a new member of the Colosseum, your profile would probably look like the one in the screenshot
          below. Follow these steps to set up your profile completely.
          <ol>
            <li>Click the ‘pencil’ icon to edit your profile image</li>
            <li>Click “Connect wallet” to connect your Web3 wallet</li>
            <li>Input your First and Last Names</li>
            <li>Choose and input a unique username</li>
            <li>Click “Connect” next to Discord to connect your Discord to the Colosseum</li>
            <li>
              Input your phone number.
              <img src="assets/icons/faqs/profile_4.png" alt="Profile" lazyLoad />
            </li>
          </ol>
        </li>
      </ol>
      <app-infographics [infographics]="infographics['item3']"></app-infographics>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item4" (opened)="setPanelOpenState('item4')" (afterExpand)="scrollToExpanded('item4')">
    <mat-expansion-panel-header>
      <mat-panel-title>How do I Buy $GMRX tokens?</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState == 'item4'">
      <p>To get started, click the “Buy GMRX” button at the top right corner of The Colosseum.</p>
      <img src="assets/icons/faqs/buy_gmrx_1.png" alt="Buy GMRX" lazyLoad />
      <p>
        You can purchase GMRX tokens on centralized exchanges (CEX) like
        <a href="https://www.bybit.com/" target="_blank" rel="noopener noreferrer">Bybit</a> or decentralized exchanges
        like <a href="https://pancakeswap.finance/" target="_blank" rel="noopener noreferrer">Pancake Swap</a>. Here is
        a full
        <a href="https://coinmarketcap.com/currencies/gaimin/#Markets" target="_blank" rel="noopener noreferrer"
          >list</a
        >
        of the exchanges (markets) where you can purchase GMRX tokens. We also have a feature to buy GMRX tokens
        directly from your Card/Bank.
      </p>
      <img src="assets/icons/faqs/buy_gmrx_2.png" alt="Buy GMRX" lazyLoad />
      <p>
        With centralized exchanges, you’d need to create an account with them while with decentralized exchanges, you
        would need some native coins ($BNB in this case) and to connect your Web3 wallet to swap (buy) $GMRX tokens.
      </p>
      <h2>How to Buy $GMRX on a Centralised Exchange (using Bybit as an example)</h2>
      <ol>
        <li>Access Buy Crypto: Click on "Buy Crypto" in the top left corner of the Bybit website or app.</li>
        <li>Select a provider: Choose a trusted third-party payment provider from the available options.</li>
        <li>
          Enter details: Specify the amount of fiat currency you want to spend and the token (GMRX) you wish to
          purchase.
        </li>
        <li>
          Choose a payment method: Select your preferred payment method, such as bank transfer, credit/debit card, or
          other supported options.
        </li>
        <li>Review and confirm: Carefully check the transaction details, including fees and estimated arrival time.</li>
        <li>Complete payment: Follow the instructions provided by the payment provider to finalize the payment.</li>
        <li>
          Receive tokens: Once the payment is successful, the purchased tokens will be deposited into your Bybit Funding
          Account.
        </li>
      </ol>
      <h2>How to Buy $GMRX on a Decentralised Exchange (using Pancake as an example)</h2>
      <ol>
        <li>
          <h3>Have $BNB in your wallet</h3>
          <p>
            To be able to purchase tokens on the BNB Smart Chain (BSC Network), you need to have the right amount of
            $BNB in your Web 3 wallet. This amount should be able to cover the amount of $GMRX tokens you want to buy,
            as well as your gas fees which would be required to complete your transactions on the network.
          </p>
        </li>
        <li>
          <h3>Connect your wallet</h3>
          <p>
            PancakeSwap works with various wallets, including MetaMask, Trust Wallet, and Binance Chain Wallet. Visit
            the
            <a href="https://pancakeswap.finance/" target="_blank" rel="noopener noreferrer">PancakeSwap website</a> and
            click on "Connect Wallet" in the top right corner. Select your wallet (or use Wallet Connect) and follow the
            on-screen instructions to connect it.
          </p>
        </li>
        <li>
          <h3>Choose the token you want to buy</h3>
          <p>
            In the exchange section, you'll see two fields. In the top field, select the token you want to use to pay
            for your purchase (usually BNB, the native coin of the BSC; you would need to have this already in your
            wallet). Also, note that since $GMRX is a BNB Smart Chain (BSC) token, you’d need some BNB for gas fees to
            perform the swap transaction. In the bottom field, enter the name or contract address of the token you want
            to buy. You can browse tokens using the search bar or scroll through the listed options.
          </p>
        </li>
        <li>
          <h3>Set the amount</h3>
          <p>
            Enter the amount of the token you want to buy in the bottom field. Alternatively, you can enter the amount
            of the payment token you want to spend in the top field, and the corresponding amount of the desired token
            will be calculated automatically.
          </p>
        </li>
        <li>
          <h3>Review and approve</h3>
          <p>
            Check the details of your swap, including the estimated amount of tokens you'll receive and the transaction
            fees. If everything looks good, click "Swap." Depending on your wallet, you will need to approve the
            transaction in your wallet. Follow the on-screen instructions in your wallet to complete the approval.
          </p>
        </li>
        <li>
          <h3>Confirm the swap</h3>
          <p>
            A confirmation window will appear, showing the final details of the swap. Review the information again and
            click "Confirm Swap" if everything is correct.
          </p>
        </li>
        <li>
          <h3>Wait for the transaction to complete</h3>
          <p>
            Once you confirm the swap, your wallet will send a transaction to the BSC network. It might take a few
            seconds or minutes for the transaction to be processed, depending on network congestion. You can track the
            progress of your transaction in your wallet or on a block explorer like
            <a href="https://bscscan.com/" target="_blank" rel="noopener noreferrer">BSC Scan</a>.
          </p>
        </li>
        <li>
          <h3>Check for purchased tokens in your wallet</h3>
          <p>
            Once the transaction is confirmed, the tokens you bought will be credited to your wallet. And anytime your
            wallet is connected to The Colosseum, you should be able to view your GMRX token balance and also access
            your tokens.
          </p>
        </li>
      </ol>
      <p>If you bought on a centralized exchange, you would need to send the GMRX tokens to your Web3 wallet.</p>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item5" (opened)="setPanelOpenState('item5')" (afterExpand)="scrollToExpanded('item5')">
    <mat-expansion-panel-header>
      <mat-panel-title>How do I Send a $GMRX token to my wallet from a centralized exchange?</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState == 'item5'">
      <ol>
        <li>
          Access Funding Account: If you just bought via a Centralised Exchange like Bybit (as shown above), your tokens
          should be in your Funding wallet. Click on "Assets" and then "Funding Account" on the top bar. If you have
          your tokens in your Bybit Spot account, you can also send your tokens from there.
        </li>
        <li>Select the token: Locate the token you want to withdraw and click on "Withdraw" beside it.</li>
        <li>Choose the withdrawal method: Select "External Wallet."</li>
        <li>
          Fill in withdrawal details:
          <ul>
            <li>Withdrawal address: Enter your Venly wallet address accurately.</li>
            <li>Chain type: Choose the appropriate blockchain network (BEP-20 in this case).</li>
            <li>Amount: Specify the amount of GMRX tokens you wish to withdraw.</li>
          </ul>
        </li>
        <li>Review and confirm: Double-check the withdrawal information and click "Confirm."</li>
        <li>
          Security verification: Complete the security verification process, such as entering a password or 2FA code.
        </li>
        <li>Track status: Monitor the withdrawal status under "Recent Withdrawals" in your Funding Account.</li>
      </ol>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item6" (opened)="setPanelOpenState('item6')" (afterExpand)="scrollToExpanded('item6')">
    <mat-expansion-panel-header>
      <mat-panel-title>How do I connect my Web3 wallet to The Colosseum?</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState == 'item6'">
      <p>Now that you have your Web3 wallet with GMRX tokens, what remains is connecting it to The Colosseum.</p>
      <ul>
        <li>
          Still logged in on The Colosseum, click on the “Connect Wallet” button on the top right corner of the
          Colosseum.
          <img src="assets/icons/faqs/wallet_connect_1.png" alt="Connect Wallet" lazyLoad />
        </li>
        <li>
          Next, you will be presented with various wallet options to connect with. The system also automatically tries
          to detect Web3 wallets in your device; you should see them here or try using the Wallet Connect QR code method
          to connect your wallet. Remember, your wallet has to be BEP-20 compatible for you to interact on the
          Colosseum; for this example, we will be using the Venly wallet. So, click “Venly” to proceed.
          <img src="assets/icons/faqs/wallet_connect_2.png" alt="Connect Wallet" class="vertical" lazyLoad />
        </li>
        <li>
          Click “Open” to open Venly and start the connection process.
          <img src="assets/icons/faqs/wallet_connect_3.png" alt="Connect Wallet" class="vertical" lazyLoad />
        </li>
        <li>
          Now log into your Venly wallet.
          <img src="assets/icons/faqs/wallet_connect_4.png" alt="Connect Wallet" class="vertical" lazyLoad />
        </li>
        <li>
          Click “Review” to view the connection request from The Colosseum.
          <img src="assets/icons/faqs/wallet_connect_5.png" alt="Connect Wallet" class="vertical" lazyLoad />
        </li>
        <li>
          Click “Approve” to finally approve the connection. You should see a success message appear if your connection
          was successful. When you go back to the Colosseum, your wallet should be connected to the platform.
          <img src="assets/icons/faqs/wallet_connect_6.png" alt="Connect Wallet" class="vertical" lazyLoad />
        </li>
      </ul>
      <app-infographics [infographics]="infographics['item6']"></app-infographics>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item7" (opened)="setPanelOpenState('item7')" (afterExpand)="scrollToExpanded('item7')">
    <mat-expansion-panel-header>
      <mat-panel-title>How to donate $GMRX to creators on the Colosseum</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState == 'item7'">
      <ol>
        <li>
          On the Colosseum home page, click “Streams” in the bottom menu.
          <img src="assets/icons/faqs/streams_1.png" alt="Donate" lazyLoad />
        </li>
        <li>
          On the Streams page, you can see available live streams from various creators.
          <img src="assets/icons/faqs/streams_2.png" alt="Donate" lazyLoad />
        </li>
        <li>
          Click any stream of your choice and you can gift any amount of available $GMRX in your wallet to the creator
          by clicking “Donate”.
          <img src="assets/icons/faqs/streams_3_donate.png" alt="Donate" lazyLoad />
        </li>
      </ol>
      <app-infographics [infographics]="infographics['item7']"></app-infographics>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel id="item8" (opened)="setPanelOpenState('item8')" (afterExpand)="scrollToExpanded('item7')">
    <mat-expansion-panel-header>
      <mat-panel-title>How to earn tokens while streaming on the Colosseum</mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState == 'item8'">
      <ol>
        <li>
          Go to the “Streams” tab, at the left side of your screen.
          <img src="assets/icons/faqs/streams_1.png" alt="Streams" lazyLoad />
        </li>
        <li>
          Choose any available stream of your choice from the following Live options.
          <img src="assets/icons/faqs/streams_2.png" alt="Streams" lazyLoad />
        </li>
        <li>
          You will see your earned tokens at the top and your tokens will also keep accumulating with the more time you
          stream on the platform. You can withdraw your earned tokens to your Web3 wallet once you hit 400 tokens, by
          simply clicking “Claim”.
          <img src="assets/icons/faqs/streams_3_claim.png" alt="Streams" lazyLoad />
        </li>
        <li>
          You will be required to confirm your wallet address and then click “Claim” again.
          <img src="assets/icons/faqs/streams_4.png" alt="Streams" lazyLoad />
        </li>
        <li>
          On doing that, your tokens will be withdrawn from your streaming earnings on the Colosseum and sent to your
          Web3 wallet.
        </li>
      </ol>
      <app-infographics [infographics]="infographics['item8']"></app-infographics>
    </div>
  </mat-expansion-panel>
</mat-accordion>
