import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe-decorator';
import { Subscription } from 'rxjs';
import { StoreProductItemModalComponent } from '../../shared/components/modals/store-product-item-modal/store-product-item-modal.component';
import { StoreService } from '../../shared/services/store.service';
import { AdditionalService } from '../../shared/services/additional.service';
import { Button, Product } from '../../shared/intarfaces';
import { BUTTON_SIZE, BUTTON_TYPE, COLOSSEUM_TIERS } from '../../shared/enums';

@Component({
  selector: 'store-page',
  templateUrl: './store-page.component.html',
  styleUrls: ['./store-page.component.scss']
})
export class StorePageComponent implements OnInit {
  @AutoUnsubscribe()
  private productsSubs: Subscription = {} as Subscription;

  customOptions: OwlOptions = {
    items: 1,
    nav: false,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    margin: 10,
    navSpeed: 500,
    startPosition: 0,
    responsive: {
      0: {
        items: 1
      }
    }
  };

  readonly signInBtn: Button = {
    name: 'Sign in',
    size: BUTTON_SIZE.MD,
    type: BUTTON_TYPE.DARK
  };
  products: Product[] | undefined;
  prices = [
    { type: COLOSSEUM_TIERS.SILVER, title: 'Silver  price', active: false },
    { type: COLOSSEUM_TIERS.GOLD, title: 'Gold  price', active: true },
    { type: COLOSSEUM_TIERS.PLATINUM, title: 'Platinum  price', active: false }
  ];

  constructor(
    private additionalService: AdditionalService,
    public storeService: StoreService,
    private dialogService: MatDialog
  ) {
    this.additionalService.isHomePageTemplate$.next(false);
  }

  ngOnInit() {
    this.productsSubs = this.storeService.getProducts().subscribe((resp) => {
      if (resp.success) {
        this.products = resp.data?.filter((item) => item.status === 'active');
      }
    });
    this.storeService.updatePrices(this.prices);
  }

  goToPage(productName: string) {
    window.open(`https://shop.gaimingladiators.gg/products/${productName}`, '_blank');
  }

  openProduct(product: Product) {
    this.dialogService.open(StoreProductItemModalComponent, {
      panelClass: ['dialog-overlay-pane', 'dialog-store-product'],
      data: product,
      autoFocus: false
    });
  }
}
